// Here you can add other styles
@font-face {
  font-family: 'BungeeHairline-Regular';
  src: local('BungeeHairline-Regular'), url(../assets/fonts/BungeeHairline-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Charm-Bold';
  src: local('Charm-Bold'), url(../assets/fonts/Charm-Bold.ttf) format('truetype');
}

.pre_line_white_space {
  white-space: pre-line;
}